import Info from '@components/Info';
import Link from '@components/UI/Link';
import Picture from '@components/UI/Picture';
import Heading from '@components/UI/Typography/Heading';
import Paragraph from '@components/UI/Typography/Paragraph';

import catOnSpaceImage from '@images/404.png';

import Navigation from '@layouts/Navigation/Traqq';

import React, { useEffect, useState } from 'react';

const NotFound = () => {
    const [navbar, setNavbar] = useState(106);

    useEffect(() => setNavbar(document.querySelector('.navbar')), []);

    return (
        <>
            <Navigation
                theme='dark'
                background='violet'
            />

            <div
                className='not-found-wrap'
            >
                <div
                    className='not-found'
                    style={ { height: `calc(100vh - ${ navbar > 0 ? navbar : navbar.offsetHeight }px)` } }
                >
                    <Info>
                        <Heading
                            tag='h1'
                            color='white'
                            mods={ { '12xl': true } }
                        >
                            404
                        </Heading>

                        <Paragraph
                            tag='p'
                            size='lg'
                            color='white'
                        >
                            Page not found! Meanwhile, <Link href='/' mods={ { underline: true } }>read how to increase productivity</Link> with Traqq and manage remote and local employees.
                        </Paragraph>
                    </Info>

                    <div
                        className='not-found__image'
                    >
                        <Picture
                            image={ catOnSpaceImage }
                            alt='404'
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default NotFound;
