import { NextSeoTraqq } from '@components/Seo';
import Layout from '@layouts/Layout';
import NotFound from '@screens/Errors/404';

import React, { memo } from 'react';

const title = 'Page not found';
const description = 'Page not found... Go to the home page of Traqq - a simple and intuitive time tracker designed for teams and individuals a like.';

const NotFoundPage = () => (
    <Layout
        title={ title }
        description={ description }
    >
        <NextSeoTraqq
            noindex={true}
            nofollow={true}
        />
        <NotFound />
    </Layout>
);

export default memo(NotFoundPage);
